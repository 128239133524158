<template>
    <div class="faq">
        <Header :pageId1="pageId" :cId1="cId" />
        <div class="banner">
            <div class="container">
                <div class="title">Sell My Car for Cash</div>
            </div>
        </div>
        <div class="main">

<div class="container">

    <div class="warpper">

        <div class="ddRow">
            <div class="ddCol-12">
                <h2>“I Want to Sell My Car for Cash”, Here is How We Can Help You</h2>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <p>If the thought “I want to <a href="./about.html">sell my car for cash</a>” has been lingering in the back of your head for a while, we have the solution and the cash that you are looking for. You can sell your car if it is financed or not. We will need a few documents from you, but our four-step process makes the entire deal a pleasant experience.</p>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <h3>How Can I Sell My Car for Cash Today?</h3>
                <p>Our sole aim is to make selling your car as simple as possible with the least amount of effort and paperwork. Here is how we do it:</p>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-6">
                <ul>
                    <li><strong>Dedication.</strong> Since you have bought your car either new or second-hand, you are familiar with the process of obtaining one. When you need cash or a new car, the last thing you want is a dragged out process. We are dedicated to our clients, and our vision is that we will make selling your car a seamless process.</li>
                    <li><strong>Accurate quotes.</strong> When we give you a quote for your car, we also provide you with research, experience and accuracy in terms of price and resell value. We ensure that the client gets the maximum amount for the car’s value.</li>
                    <li><strong>Any cars.</strong> Is your car an older model? We buy any cars, no matter the make or model. We provide you with a same-day call-back, inspection and payment to prove that we are serious about simplicity and urgency.</li>
                </ul>
            </div>
            <div class="ddCol-6">
                <img src="~@/assets/images/choose.png">
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <h3>Questions You Might Have Before You Sell Your Car for Cash</h3>
                <p>As part of our customer satisfaction policy, we aim to answer some of the questions you may have to set your mind at ease beforehand:</p>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-6">
                <img src="~@/assets/images/row2_l.png">
            </div>
            <div class="ddCol-6">
                <ul>
                    <li><strong>How do I get paid?</strong> We prefer to do business the safest way possible, so we will arrange a bank transfer which will clear within 24 hours.</li>
                    <li><strong>Documents.</strong> Even though we make the process as easy as possible and process all info on the same day, we will need your proof of ownership of the car and your driver’s licence and vehicle registration documents. If your car is still financed, we will need a pay-out letter from the relevant financial institution.</li>
                    <li><strong>Collection.</strong> Once you accept our quote, please ensure that all car documents such as manuals and the service book are with the car. We will collect the vehicle at a time and date convenient for you.</li>
                </ul>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <p>We want to provide you with the best service and take your car off your hands with the least effort. You don’t have to change the ownership or put your vehicle through a roadworthy test – you simply hand it over - we take care of the rest.</p>
            </div>
        </div>
        <div class="ddRow">
            <div class="ddCol-12">
                <h3>Why Our Products Are Good for You</h3>
                <p>If you only had one goal in mind when selling your car, and that was that “I want cash for my car”, then you won’t find a faster approach than ours. If you have all your documents in order and are ready to let go of your car, <a href="./start.html">contact us</a> for an inspection.</p>
            </div>
        </div>
        
    </div>

</div>
        
</div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
    watch: {
        $route: {
            deep: true,
            handler(val) {
                this.pageId = val.query.id;
                this.cId = val.query.cId;
            },
        },
    },
    created() {
        this.pageId = this.$route.query.id;
        this.cId = this.$route.query.cId;
        this.pageName = this.$route.query.name;
    },
};
</script>
  
<style scoped lang="less">
.ddRow {  font-family: Ubuntu-Regular, Ubuntu; color: rgba(0, 0, 0, 0.6); display: flex; align-items:center; margin: 1% 0; width: 100%; }
.ddRow h2, .ddRow h3 { font-weight: bold; color: #004AAD; margin: 4% 0 2% 0; text-align: center; }
.ddRow ul li { list-style-type: disclosure-closed; margin-bottom: 2%; margin-left: 4%; }
.ddRow img { width: 100%; }
.ddCol-12 { width: 100%; }
.ddCol-6 { width: 50%; padding: 2%; float: left; }
.ddRow:after { content: ""; display: table; clear: both; }
@media only screen and (max-device-width: 960px) { .ddRow {display: block; } .ddCol-6 { width: 100%; } }
.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}</style>
  